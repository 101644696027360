//SettingsPage.js
import React, { useState, useEffect } from 'react';
import { doc, setDoc, getDoc, collection, getDocs } from "firebase/firestore";  // Import Firestore
import { db, app, auth} from './firebase';  // Import Firestore db instance
import { requestNotificationPermission } from './firebase';
import { getFunctions, httpsCallable } from "firebase/functions"; // Import functions
import { getMessaging, onMessage } from "firebase/messaging";



const functions = getFunctions(app, "us-central1");

const SettingsPage = ({ user, onUpdatePreferences, onLocationRefresh, updateOffset, notificationTimings }) => {
  const [defaultQasr, setDefaultQasr] = useState(null);  // State to track default Qasr preference
  const [loading, setLoading] = useState(true);
  const [storedLatitude, setStoredLatitude] = useState(null); // State for displaying stored latitude
  const [storedLongitude, setStoredLongitude] = useState(null); // State for displaying stored longitude
  const [message, setMessage] = useState(""); // State for feedback messages
  const [notificationPreferences, setNotificationPreferences] = useState([]); // State to store notification preferences
  const prayerOrder = ['fajr', 'zuhr', 'asr', 'maghrib', 'isha'];
  const [offsetMinutes, setOffsetMinutes] = useState(0);


  const handleNotificationPermission = async () => {
    await requestNotificationPermission(user.uid);
};

const handleTestNotification = async () => {
  const functionUrl = `https://us-central1-qazacounter.cloudfunctions.net/sendTestNotification?userId=${user.uid}`;

  try {
    const token = await auth.currentUser.getIdToken();

    const response = await fetch(functionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify({ userId: user.uid }) // Send userId in body
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log("Response from test notification:", data);
  } catch (error) {
    console.error("Error scheduling test notification:", error);
  }
};


  useEffect(() => {
    const fetchNotificationPreferences = async () => {
      try {
        if (user) {
          const notificationRef = collection(db, `users/${user.uid}/notificationPreferences`);
          const snapshot = await getDocs(notificationRef);
          const preferences = snapshot.docs.map(doc => ({
            ...doc.data(),
            prayer: doc.id,
            notify: doc.data().notify ?? false  // Set notify to false if undefined
          }));
              setNotificationPreferences(preferences);
        }
      } catch (error) {
        console.error("Error fetching notification preferences:", error);
      }
    };
  
    fetchNotificationPreferences();
  }, [user]);


  // Fetch user preferences on component mount
  useEffect(() => {
    const fetchUserPreferences = async () => {
      try {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setDefaultQasr(userData.defaultQasr || false);
          setOffsetMinutes(userData.notificationOffset || 0); // Set to stored value or 0
          updateOffset(userData.notificationOffset || 0);

  
          if (userData.location) {
            setStoredLatitude(userData.location.latitude);
            setStoredLongitude(userData.location.longitude);
          }
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchUserPreferences();
  }, [user]);
  


  const handleOffsetChange = async (e) => {
    const newOffset = parseInt(e.target.value, 10) || 0;
  
    // Update the local state immediately for UI feedback
    setOffsetMinutes(newOffset);  // Update local state in SettingsPage
    updateOffset(newOffset);  // Update the offset in App.js
  
    try {
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(userDocRef, { notificationOffset: newOffset }, { merge: true });
      setMessage("Offset updated successfully!"); // Show a confirmation message
      setTimeout(() => setMessage(""), 3000); // Clear the message after 3 seconds
    } catch (error) {
      console.error("Error saving offset to Firestore:", error);
    }
  };
  
  
  
  // Handle the toggle action and save directly to Firestore
  const handleToggleChange = async () => {
    try {
      const updatedQasr = !defaultQasr;  // Toggle the Qasr preference
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(userDocRef, { defaultQasr: updatedQasr }, { merge: true });
      setDefaultQasr(updatedQasr);
      onUpdatePreferences();
    } catch (error) {
      console.error('Error saving preferences:', error);
    }
  };

  const handleNotifyChange = async (prayer, newValue) => {
    const updatedPreferences = notificationPreferences.map(pref =>
      pref.prayer === prayer ? { ...pref, notify: newValue } : pref
    );
    setNotificationPreferences(updatedPreferences);
  
    try {
      const prayerDocRef = doc(db, `users/${user.uid}/notificationPreferences`, prayer);
      await setDoc(prayerDocRef, { notify: newValue }, { merge: true });
      console.log(`Updated 'notify' preference for ${prayer} to ${newValue}`);
    } catch (error) {
      console.error(`Error updating notify preference for ${prayer}:`, error);
    }
  };
  

  const handleLocationRefresh = () => {
    onLocationRefresh();
    setMessage("Location updated successfully!");
  };
  
  
  
  if (loading) {
    return <div>Loading preferences...</div>;  // Display a loading message until the state is ready
  }

  return (
    <div className="settings-page">
      <h2>Settings</h2>

      {/* Toggle Switch for Default Qasr */}
      <label style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
        <input 
          type="checkbox" 
          checked={defaultQasr} 
          onChange={handleToggleChange}  // Toggle and save on change
          style={{ display: 'none' }}  // Hide default checkbox
        />
        <div 
          className="custom-toggle"
          onClick={handleToggleChange}  // Ensure the toggle action is triggered on click
          style={{
            position: 'relative',
            width: '40px',
            height: '20px',
            backgroundColor: defaultQasr ? '#4caf50' : '#ccc', // Toggle background color
            borderRadius: '15px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',  // Smooth background color transition
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '3px',
              left: defaultQasr ? '20px' : '3px',  // Move the circle based on toggle state
              width: '14px',
              height: '14px',
              backgroundColor: 'white',
              borderRadius: '50%',
              transition: 'left 0.3s ease',  // Smooth transition for sliding
            }}
          />
        </div>
        <span style={{ marginLeft: '10px' }}>Default Qasr On Start</span>
      </label>

      {/* Display Stored Location Coordinates */}
      <div style={{ marginTop: '20px', color: 'gray' }}>
        <p>Stored Latitude: {storedLatitude || 'N/A'}</p>
        <p>Stored Longitude: {storedLongitude || 'N/A'}</p>
      </div>

      {/* Location Refresh Button */}
      <button onClick={handleLocationRefresh} style={{ marginTop: '20px', padding: '10px', fontSize: '16px' }}>
        Refresh Location
      </button>
      <br />


      {/* Feedback Message */}
      {message && <p style={{ marginTop: '10px', color: 'white' }}>{message}</p>}

      {/* Notification Offset Input */}
      <br />
      <label>
      <label>Notification Offset (Minutes): </label>
          <select value={offsetMinutes} onChange={handleOffsetChange}>
            <option value={0} disabled>Select an offset...</option>
            {[5, 10, 15, 20].map((minutes) => (
              <option key={minutes} value={minutes}>
                {minutes}
              </option>
            ))}
          </select>
          <p style={{ color: "gray" }}>Please select an offset other than 0 to enable notifications.</p>
        </label>
      <br />
        {/* Notification Timings Table */}
        <h3>Notification Timings</h3>
          <table>
            <thead>
              <tr>
                <th>Prayer</th>
                <th>Notification Time</th>
                <th>Notify</th>
              </tr>
            </thead>
            <tbody>
              {notificationPreferences
                  .sort((a, b) => prayerOrder.indexOf(a.prayer) - prayerOrder.indexOf(b.prayer))
                  .map(({ prayer, time, notify }, index) => (
                      <tr key={index}>
                          <td>{prayer}</td>
                          <td>{time ? new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }) : 'N/A'}</td>
                          <td>
                              <input
                                  type="checkbox"
                                  checked={notify || false}
                                  onChange={(e) => handleNotifyChange(prayer, e.target.checked)}
                              />
                          </td>
                      </tr>
                  ))}
          </tbody>
          </table>
          <div>
            {/* Other settings */}
            <button onClick={handleNotificationPermission}>
                Enable Notifications
            </button>
        </div>
        <br />
        <button onClick={handleTestNotification}>
            Send Test Notification (background - 5-second delay - on closed app)
        </button>
        <br />

        <h4> <a
        href="https://forms.gle/JuWCbbAyAHwtSnUF7" 
        target="_blank" 
        rel="noopener noreferrer" 
        style={{ color: 'white', textDecoration: 'none' }}
    >
        Feedback Log - Click here to submit
    </a> </h4>
        <br />


    </div>
  );
};

export default SettingsPage;
