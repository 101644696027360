// prayerService.js
import { query, where, getDocs, collection, addDoc } from "firebase/firestore";
import { db } from './firebase';  // Assuming firebase.js is in the same folder

// Check for duplicate missed prayer
export const checkForDuplicate = async (userId, salahType, dateMissed) => {
    const missedPrayersRef = collection(db, `users/${userId}/missedPrayers`);
  
    const q = query(
      missedPrayersRef,
      where("salahType", "==", salahType),
      where("dateMissed", "==", dateMissed)
    );
  
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };
  

// Log missed prayer
export const logMissedPrayer = async (userId, salahType, dateMissed, qasr = false) => {
  const isDuplicate = await checkForDuplicate(userId, salahType, dateMissed);
  
  if (isDuplicate) {
    alert(`You've already logged a missed ${salahType} for this date. Please select another date using the date picker.`);
    return true;
  }

  const missedPrayersRef = collection(db, `users/${userId}/missedPrayers`);
  
  await addDoc(missedPrayersRef, {
    salahType: salahType,
    dateMissed: dateMissed,
    madeUp: false,   // Default to false
    qasr: qasr      
  });

  alert(`Missed ${salahType} for ${dateMissed} has been logged.`);
  return false;
};
