//MissedPrayerList.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from './firebase';
import { collection, query, where, getDocs, deleteDoc, doc, updateDoc, orderBy, limit } from 'firebase/firestore';

const titleCase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// Helper function to sort dates in MM-DD-YYYY format by YYYY-MM-DD order
const sortDates = (dateA, dateB) => {
  // Split MM-DD-YYYY into its components
  const [monthA, dayA, yearA] = dateA.split('-');
  const [monthB, dayB, yearB] = dateB.split('-');

  // Reformat to YYYY-MM-DD for lexical comparison
  const formattedA = `${yearA}-${monthA}-${dayA}`;
  const formattedB = `${yearB}-${monthB}-${dayB}`;

  // Compare formatted dates as strings
  return formattedA.localeCompare(formattedB);
};


const MissedPrayerList = ({ user, missedPrayers, setMissedPrayers, refreshOldestPrayers }) => {
  const { prayerType } = useParams();  // Get the prayer type from the URL
  const [prayerList, setPrayerList] = useState([]);
  const [oldestDate, setOldestDate] = useState('');  // Local state for oldest missed date


  const fetchOldestUnmadePrayer = async () => {
    const missedPrayersRef = collection(db, `users/${user.uid}/missedPrayers`);
    const q = query(
      missedPrayersRef,
      where("salahType", "==", prayerType),
      where("madeUp", "==", false),
    );
    try {
      const querySnapshot = await getDocs(q);
      const prayers = querySnapshot.docs.map(doc => doc.data());
  
      if (prayers.length > 0) {
        // Sort prayers by dateMissed using the custom sortDates function
        prayers.sort((a, b) => sortDates(a.dateMissed, b.dateMissed));
  
        // Set the oldest unmade prayer date to the first prayer in the sorted list
        setOldestDate(prayers[0].dateMissed);
      } else {
        setOldestDate('');
      }
    } catch (error) {
      console.error('Error fetching oldest unmade prayer:', error);
    }
  };
  
  
  // Function to fetch missed prayers for the selected prayer type
  const fetchMissedPrayers = async () => {
    const missedPrayersRef = collection(db, `users/${user.uid}/missedPrayers`);
    const q = query(
      missedPrayersRef,
      where("salahType", "==", prayerType)
    );
  
    try {
      const querySnapshot = await getDocs(q);
      const prayers = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Sort using the custom sortDates function in ascending order (oldest first), then reverse for display
      prayers.sort((a, b) => sortDates(a.dateMissed, b.dateMissed)).reverse();
      setPrayerList(prayers); // Set sorted prayers for display with newest first
  
      // Find the oldest unmade prayer for the label (keep it in ascending order)
      const oldestUnmadePrayer = prayers.slice().reverse().find(prayer => !prayer.madeUp);
      setOldestDate(oldestUnmadePrayer?.dateMissed || '');  // Update oldest unmade prayer
  
    } catch (error) {
      console.error('Error fetching missed prayers:', error);
      setPrayerList([]);  // Clear list on error
      setOldestDate('');  // Clear oldest date on error
    }
  };
  
  
  

  // Initial fetch on component load
  useEffect(() => {
    fetchMissedPrayers();
    fetchOldestUnmadePrayer();
  }, [prayerType, user]);

  // Function to update Qasr or MadeUp flags
  const handleUpdateFlag = async (prayer, flagType, newValue) => {
    try {
      const prayerRef = doc(db, `users/${user.uid}/missedPrayers/${prayer.id}`);
      
      // Update the specific flag (madeUp or qasr) in Firestore
      await updateDoc(prayerRef, { [flagType]: newValue });
  
      // Update local state after database update
      setPrayerList(prevList =>
        prevList.map(p => 
          p.id === prayer.id ? { ...p, [flagType]: newValue } : p
        )
      );
  
      // If flagType is 'madeUp', adjust the counter locally and in Firestore
      if (flagType === 'madeUp') {
        setMissedPrayers(prev => ({
          ...prev,
          [prayer.salahType]: newValue 
            ? Math.max(prev[prayer.salahType] - 1, 0) // Decrement if madeUp is checked
            : prev[prayer.salahType] + 1 // Increment if madeUp is unchecked
        }));
  
        // Update the Firestore counter for missed prayers
        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, {
          [`prayers.${prayer.salahType}`]: newValue
            ? Math.max(missedPrayers[prayer.salahType] - 1, 0) // Decrement in Firestore
            : missedPrayers[prayer.salahType] + 1 // Increment in Firestore
        });
      }
  
      // Re-fetch the missed prayers to update the oldest missed prayer
      await fetchMissedPrayers();
      refreshOldestPrayers();
      await fetchOldestUnmadePrayer();
    } catch (error) {
      console.error('Error updating prayer flag:', error);
    }
  };
  
  

  // Function to delete a missed prayer and decrement the counter if not made up
  const handleDelete = async (prayerId, salahType, isMadeUp) => {
    try {
      
        const prayerRef = doc(db, `users/${user.uid}/missedPrayers/${prayerId}`);
        
        // Delete the prayer document from Firestore
        await deleteDoc(prayerRef);
    
        // Update the local prayer list state after deletion
        setPrayerList(prevList => prevList.filter(prayer => prayer.id !== prayerId));
    
        // If the prayer was not made up, decrement the counter
        setMissedPrayers(prev => ({
          ...prev,
          [salahType]: Math.max(prev[salahType] - 1, 0)
        }));
  
        // Update Firestore with the new counter value
        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, {
          [`prayers.${salahType}`]: Math.max(missedPrayers[salahType] - 1, 0)
        });
  
        // Re-fetch the missed prayers to update the oldest missed prayer
        await fetchMissedPrayers();
        refreshOldestPrayers();
        await fetchOldestUnmadePrayer();
      
    } catch (error) {
      console.error('Error deleting prayer:', error);
    }
  };
  
  
  // Get the missed count for this prayer from state
  const missedCount = missedPrayers[prayerType];

  return (
    <div>
      {/* Display the counter and oldest missed date at the top */}
      <div className="prayer-counter-header">
        <h2>{titleCase(prayerType)} - Missed: {missedCount}</h2>
        {oldestDate && <p>Oldest Missed: {oldestDate}</p>}
        <Link to="/" className="back-link">← Return to all prayers</Link>
      </div>

      {/* Missed prayers table */}
      <table>
        <thead>
          <tr>
            <th>Date</th>
            {prayerType !== 'maghrib' && prayerType !== 'fajr' && prayerType !== 'roza' && <th>Qasr</th>}
            <th>MadeUp</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {prayerList.map(prayer => (
            <tr key={prayer.id}>
              <td>{prayer.dateMissed}</td>
              {prayerType !== 'maghrib' && prayerType !== 'fajr' && prayerType !== 'roza' && (
                <td>
                  <input 
                    type="checkbox" 
                    checked={prayer.qasr} 
                    onChange={(e) => handleUpdateFlag(prayer, 'qasr', e.target.checked)} 
                  />
                </td>
              )}
              <td>
                <input 
                  type="checkbox" 
                  checked={prayer.madeUp} 
                  onChange={(e) => handleUpdateFlag(prayer, 'madeUp', e.target.checked)} 
                />
              </td>
              <td>
                <button onClick={() => handleDelete(prayer.id, prayerType, prayer.madeUp)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MissedPrayerList;
