//app.js

//IMPORT STATEMENTS FROM OTHER PAGES/SERVICES ****************************************************
//IMPORT STATEMENTS FROM OTHER PAGES/SERVICES ****************************************************
//IMPORT STATEMENTS FROM OTHER PAGES/SERVICES ****************************************************
//IMPORT STATEMENTS FROM OTHER PAGES/SERVICES ****************************************************


import React, { useState, useEffect } from 'react';
import './App.css';
import { signInWithPopup, signOut, onAuthStateChanged } from "firebase/auth";
import { addDoc, collection, query, where, getDocs, doc, setDoc, getDoc, orderBy, limit } from "firebase/firestore";
import { FaPlusCircle, FaMinusCircle, FaHome, FaCog, FaPowerOff, FaGoogle, FaStar, FaInfoCircle, FaCalendarAlt } from 'react-icons/fa'; // Stylish icons for increment/decrement
import { BrowserRouter as Router, Route, Routes, Link, Navigate} from 'react-router-dom';  // React Router components
import SettingsPage from './SettingsPage';  // Import the Settings page
import { db, auth, provider, messaging } from './firebase';
import { logMissedPrayer, checkForDuplicate } from './prayerService';  // Your service functions for handling prayer logging
import MissedPrayerList from './MissedPrayerList'; // Import the MissedPrayerList component
import Tutorial from './Tutorial'; // Import the tutorial component
import { getToken, onMessage } from 'firebase/messaging';


// FIREBASE CONFIG *******************************************************************************

const firebaseConfig = {
  apiKey: "AIzaSyDxu1hrjt79NArpL3zIJomKyQFtuDKP_dE",
  authDomain: "qazacounter.firebaseapp.com",
  projectId: "qazacounter",
  storageBucket: "qazacounter.appspot.com",
  messagingSenderId: "135562962712",
  appId: "1:135562962712:web:90ccf658f92268302fa06d",
  measurementId: "G-S7P3V1PQDL"
};


// MAIN APP FUNCTION ********************************************************************************
function App() {
  

  
  // HELPER FUNCTIONS ********************************************************************************
  // HELPER FUNCTIONS ********************************************************************************
  // HELPER FUNCTIONS ********************************************************************************
  // HELPER FUNCTIONS ********************************************************************************
    
  const formatTo12Hour = (time24) => {
    if (!time24) return "N/A";
    const [hours, minutes] = time24.split(":");
    let hours12 = hours % 12 || 12; // Convert 0 to 12 for midnight
    const period = hours < 12 ? "a" : "p";
    return `${hours12}:${minutes}${period}`;
  };

  const getLocalTodayDate = () => {
    const today = new Date();
    // Get year, month, and day from local time
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(today.getDate()).padStart(2, '0'); // Pad day to 2 digits
    
    return `${year}-${month}-${day}`;
  };

    // Helper function to format a date to MM-DD-YYYY
    const formatDateString = (dateString) => {
      if (!dateString) return ''; // Handle undefined or empty date values
  
      const parts = dateString.split('-');
      
      // Ensure we have exactly three parts and the first is in YYYY format
      if (parts.length === 3 && parts[0].length === 4) {
        const [year, month, day] = parts;
        const formattedMonth = month.padStart(2, '0');
        const formattedDay = day.padStart(2, '0');
        return `${formattedMonth}-${formattedDay}-${year}`; // MM-DD-YYYY format
      }
  
      // If it doesn’t match expected patterns, return a fallback empty string or the original
      return dateString;
    };
  

  const sortDates = (dateA, dateB) => {
    const [monthA, dayA, yearA] = dateA.split('-');
    const [monthB, dayB, yearB] = dateB.split('-');
    
    const formattedA = `${yearA}-${monthA}-${dayA}`;
    const formattedB = `${yearB}-${monthB}-${dayB}`;
    
    return formattedA.localeCompare(formattedB); // Sort by YYYY-MM-DD order
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////


  const [user, setUser] = useState(null);
  const [missedPrayers, setMissedPrayers] = useState({ });
  const [showTutorial, setShowTutorial] = useState(false);
  const [loading, setLoading] = useState(true); // Track data loading state
  const [dateMissed, setDateMissed] = useState(getLocalTodayDate());  // State for the selected date
  const [isQasr, setIsQasr] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false); // Track if the user is new
  const [prayerTimings, setPrayerTimings] = useState({});
  const [location, setLocation] = useState(null); // Store latitude and longitude here
  const [offsetMinutes, setOffsetMinutes] = useState(0);  // User-defined offset in minutes
  const [notificationTimings, setNotificationTimings] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false);
  const [prayerType, setPrayerType] = useState('');
  const [overallOldestPrayer, setOverallOldestPrayer] = useState(null); // State to track the overall oldest missed prayer
  const [hideDate, setHideDate] = useState(() => {
    // Retrieve the stored value or default to false if it doesn't exist
    return localStorage.getItem("hideDate") === "true";
  });
  const [loadingLocation, setLoadingLocation] = useState(true);






  // LOCATION / NOTIFICATION TIMINGS/PERMISSIONS ETC ***************************************************
  // LOCATION / NOTIFICATION TIMINGS/PERMISSIONS ETC ***************************************************
  // LOCATION / NOTIFICATION TIMINGS/PERMISSIONS ETC ***************************************************
  // LOCATION / NOTIFICATION TIMINGS/PERMISSIONS ETC ***************************************************


  const getLocation = (onSuccess, onError = (err) => console.error("Location error:", err)) => {
    setLoadingLocation(true);  // Start loading
    try {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const locationData = { latitude, longitude };
            setLocation(locationData); // Update local state
            setLoadingLocation(false);  // Loading complete
            onSuccess(locationData); // Trigger any success handlers
          },
          (error) => {
            console.error("Geolocation error:", error);
            onError(error);
            setLoadingLocation(false); // End loading on error
          },
          { enableHighAccuracy: true }
        );
      } else {
        console.error("Geolocation is not supported.");
        onError(new Error("Geolocation not supported."));
        setLoadingLocation(false); // End loading if geolocation isn't supported
      }
    } catch (error) {
      console.error("An error occurred in getLocation:", error);
      onError(error);
      setLoadingLocation(false);  // End loading on exception
    }
  };

  const saveLocationToFirestore = async (userId, locationData) => {
    if (!userId || !locationData) return;
  
    const userDocRef = doc(db, "users", userId);
  
    try {
      await setDoc(userDocRef, { location: locationData }, { merge: true });
      console.log("Location successfully saved to Firestore:", locationData);
    } catch (error) {
      console.error("Error saving location to Firestore:", error);
    }
  };


  const refreshLocation = async (onUpdateCoordinates) => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        const newLocation = { latitude, longitude };
        setLocation(newLocation); // Update local state

        // Save the new location to Firestore
        if (user) {
          try {
            const userDocRef = doc(db, "users", user.uid);
            await setDoc(userDocRef, { location: newLocation }, { merge: true });
            //console.log("Refreshed location saved to Firestore:", newLocation);
          } catch (error) {
            //console.error("Error saving refreshed location to Firestore:", error);
          }
        }

        // Immediately update the displayed coordinates in SettingsPage
        if (onUpdateCoordinates) {
          onUpdateCoordinates(latitude, longitude);
        }

        loadPrayerTimings(newLocation); // Reload prayer timings
      },
      (error) => {
        //console.error("Failed to refresh location:", error);
      },
      { enableHighAccuracy: true }
    );
  };


  const requestNotificationPermission = async (authUser) => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const registration = await navigator.serviceWorker.ready;
  
        const token = await getToken(messaging, {
          vapidKey: "BPuac6rrDEFODgrnUj5hgMUKQUy_EKnhUCNU9Y0E3Q1dk16prJ_l00QY54LxOahDMN6v3U0g1AoC5SAm0y1kbCU",
          serviceWorkerRegistration: registration
        });
  
        if (token) {
          console.log("FCM Token retrieved:", token);
  
          if (authUser && authUser.uid) { // Use authUser directly here
            const userDocRef = doc(db, "users", authUser.uid);
            await setDoc(userDocRef, { fcmToken: token }, { merge: true });
            console.log("FCM Token saved to Firestore for user:", authUser.uid);
          } else {
            console.error("User is not defined or not logged in.");
          }
        } else {
          console.error("Failed to retrieve FCM Token.");
        }
      } else {
        console.log("Notification permission denied.");
      }
    } catch (error) {
      console.error("Error requesting notification permission:", error);
    }
  }; 

    const updateNotificationTimings = async () => {
      if (!location) return;

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const notifTimings = await fetchNotifPrayerTimings(location.latitude, location.longitude, timezone);

      if (!notifTimings) return;

      const updatedNotificationTimings = Object.entries(notifTimings).map(([prayer, time]) => {
          const dateObject = time ? new Date(time) : null;
          const adjustedTime = dateObject ? new Date(dateObject.getTime() - offsetMinutes * 60000) : null;
          const utcTime = adjustedTime ? adjustedTime.toISOString() : null;
          return { prayer, time: utcTime }; // Store as UTC ISO string
      });

      setNotificationTimings(updatedNotificationTimings);

      try {
          updatedNotificationTimings.forEach(async ({ prayer, time }) => {
              const prayerDocRef = doc(db, `users/${user.uid}/notificationPreferences`, prayer);
              await setDoc(prayerDocRef, { time }, { merge: true });
          });
      } catch (error) {
          console.error("Error saving notification timings to Firestore:", error);
      }
  };

  /////////////////////////////////////////////////////////////////////////////////////////////

  // DATA FETCHING AND USER MANAGEMENT *********************************************************
  // DATA FETCHING AND USER MANAGEMENT *********************************************************
  // DATA FETCHING AND USER MANAGEMENT *********************************************************
  // DATA FETCHING AND USER MANAGEMENT *********************************************************
  
  const prayerPriority = {
    fajr: 1,
    zuhr: 2,
    asr: 3,
    maghrib: 4,
    isha: 5,
  };
  

  const [oldestMissedPrayers, setOldestMissedPrayers] = useState({
    fajr: '',
    zuhr: '',
    asr: '',
    maghrib: '',
    isha: '',
    roza: '',
  });


  const fetchUserPreferences = async (uid) => {
    try {
      const userDocRef = doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setIsQasr(userData.defaultQasr || false);  // Set initial isQasr based on user's preference
        setOffsetMinutes(userData.notificationOffset || 0);
      }
    } catch (error) {
      console.error('Error fetching user preferences:', error);
    }
  };

   // Fetch user data from Firestore and oldest missed prayers together
   const fetchUserDataAndOldestPrayers = async (uid) => {
    try {
      const userDocRef = doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data().prayers || missedPrayers;
        setMissedPrayers(userData);  // Set missed prayers in state
        const updatedData = { ...userData, roza: userData.roza ?? 0 };
        setMissedPrayers(updatedData);
                // Save back to Firestore if 'roza' was missing
                if (!userData.hasOwnProperty('roza')) {
                  await setDoc(userDocRef, { prayers: updatedData }, { merge: true });
                }
        // Fetch all oldest missed prayers after the user's prayer data is fetched
        await fetchAllOldestMissedPrayers(uid);
      } else {
        const defaultData = {
          prayers: {
            fajr: 0,
            zuhr: 0,
            asr: 0,
            maghrib: 0,
            isha: 0,
            roza: 0,
          },
        };
        await setDoc(userDocRef, defaultData);
        setMissedPrayers(defaultData.prayers); // Set default data in state
  
        // Even for a new user, ensure oldest missed prayers are fetched
        await fetchAllOldestMissedPrayers(uid);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false);  // Only set loading to false after both data and oldest prayers are fetched
    }
  };
  

  const fetchAllOldestMissedPrayers = async (uid) => {
    try {
      const prayerTypes = ['fajr', 'zuhr', 'asr', 'maghrib', 'isha'];
      const newOldestMissedPrayers = {};
      let overallOldestPrayer = null; // Track the overall oldest missed prayer
  
      for (let prayer of prayerTypes) {
        const missedPrayersRef = collection(db, `users/${uid}/missedPrayers`);
        const q = query(
          missedPrayersRef,
          where("salahType", "==", prayer),
          where("madeUp", "==", false)
        );
  
        const querySnapshot = await getDocs(q);
        const prayers = querySnapshot.docs.map(doc => doc.data());
  
        if (prayers.length > 0) {
          // Sort prayers by dateMissed using the custom sortDates function
          prayers.sort((a, b) => sortDates(a.dateMissed, b.dateMissed));
  
          // Set the oldest prayer for this type
          newOldestMissedPrayers[prayer] = prayers[0];
  
          // Determine the overall oldest prayer across all types
          if (
            !overallOldestPrayer || 
            sortDates(prayers[0].dateMissed, overallOldestPrayer.dateMissed) < 0 ||
            (sortDates(prayers[0].dateMissed, overallOldestPrayer.dateMissed) === 0 &&
             prayerPriority[prayer] < prayerPriority[overallOldestPrayer.salahType])
          ) {
            overallOldestPrayer = { ...prayers[0], salahType: prayer };
          }
        } else {
          // No missed prayers for this type
          newOldestMissedPrayers[prayer] = null;
        }
      }
  
      // Update state with the oldest prayers per type and the overall oldest
      setOldestMissedPrayers(newOldestMissedPrayers);
      setOverallOldestPrayer(overallOldestPrayer);
  
    } catch (error) {
      console.error('Error fetching oldest missed prayers:', error);
    }
  };


  const fetchOldestMissedPrayer = async (prayer) => {
    if (user) {
      const missedPrayersRef = collection(db, `users/${user.uid}/missedPrayers`);
      const q = query(
        missedPrayersRef,
        where("salahType", "==", prayer),
        where("madeUp", "==", false)
      );
  
      try {
        const querySnapshot = await getDocs(q);
        const prayers = querySnapshot.docs.map(doc => doc.data());
  
        if (prayers.length > 0) {
          // Sort prayers by dateMissed using the custom sortDates function
          prayers.sort((a, b) => sortDates(a.dateMissed, b.dateMissed));
  
          // Set the oldest prayer in state
          setOldestMissedPrayers((prev) => ({
            ...prev,
            [prayer]: prayers[0]
          }));
        } else {
          setOldestMissedPrayers((prev) => ({
            ...prev,
            [prayer]: null
          }));
        }
      } catch (error) {
        console.error('Error fetching oldest missed prayer:', error);
      }
    }
  };
  
  
   
  const fetchNotifPrayerTimings = async (latitude, longitude, timezone, date = null) => {
    const startDate = date || new Date().toISOString().split("T")[0];
    const baseUrl = "https://mumineen.org/api/v1/salaat";
    const url = `${baseUrl}?start=${startDate}&latitude=${latitude}&longitude=${longitude}&timezone=${timezone}`;
  
    try {
      const response = await fetch(url, {
        headers: {
          'Accept': 'application/json'
        }
      });
  
      const result = await response.json();
      const prayerData = result.data[startDate];
      if (!prayerData) {
        throw new Error("Prayer times for the specified date are missing.");
      }
  
      // Store full date-time strings without formatting for notification purposes
      const notifTimings = {
        fajr: prayerData.sunrise,
        zuhr: prayerData.zohr_end,
        asr: prayerData.asr_end,
        maghrib: prayerData.maghrib,
        isha: prayerData.nisful_layl,
      };
  
      return notifTimings;
    } catch (error) {
      console.error("Error fetching notification prayer timings:", error);
      return null;
    }
  };
  

  const fetchPrayerTimings = async (latitude, longitude, timezone, date = null) => {
    const startDate = date || new Date().toISOString().split("T")[0];
    const baseUrl = "https://mumineen.org/api/v1/salaat";
    const url = `${baseUrl}?start=${startDate}&latitude=${latitude}&longitude=${longitude}&timezone=${timezone}`;
  
   // console.log("Request URL:", url);
  
    try {
      const response = await fetch(url, {
        headers: {
          'Accept': 'application/json'
        }
      });
  
      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        const text = await response.text();
        console.error("Non-JSON response:", text);
        throw new Error("API did not return JSON.");
      }
  
      const result = await response.json();
      //console.log("Full API Response:", JSON.stringify(result, null, 2)); // Check full structure
  
      // Access the data object and then the date-specific object
      const prayerData = result.data[startDate];
      if (!prayerData) {
        throw new Error("Prayer times for the specified date are missing.");
      }
  
      // Helper function to extract only the time part
      const formatTime = (dateTime) => dateTime ? dateTime.split(" ")[1] : "N/A";
  
      // Extract and format prayer times based on the API's structure
      const timings = {
        fajr: `${formatTo12Hour(formatTime(prayerData.fajr))} - ${formatTo12Hour(formatTime(prayerData.sunrise))}`,
        zuhr: `${formatTo12Hour(formatTime(prayerData.zawaal))} - ${formatTo12Hour(formatTime(prayerData.zohr_end))}`,
        asr: formatTo12Hour(formatTime(prayerData.asr_end)),
        maghrib: formatTo12Hour(formatTime(prayerData.maghrib)),
        isha: formatTo12Hour(formatTime(prayerData.nisful_layl)),
      };
      
  
      //console.log("Parsed Prayer Timings:", timings);
  
      return timings;
    } catch (error) {
      //console.error("Error fetching prayer timings:", error);
      return null;
    }
  };

  const loadPrayerTimings = async (location) => {
    try {
      //console.log("Calling fetchPrayerTimings...");
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timings = await fetchPrayerTimings(location.latitude, location.longitude, timezone);
      if (timings) {
        setPrayerTimings(timings);
      }
    } catch (error) {
      //console.error("Error in loadPrayerTimings:", error);
    }
  };
  
  
  const refreshPreferences = () => {
    if (user) {
      fetchUserPreferences(user.uid); // Refetch preferences from Firestore
    }
  };


  ////////////////////////////////////////////////////////////////////////////////


  // EVENT AND STATE HANDLERS ****************************************************
  // EVENT AND STATE HANDLERS ****************************************************
  // EVENT AND STATE HANDLERS ****************************************************
  // EVENT AND STATE HANDLERS ****************************************************
 

  // Only update local state in App.js
    const handleQasrToggle = () => {
      const newQasrState = !isQasr;
      setIsQasr(newQasrState); // Just toggle the state in the UI for this session
    };

    //handle date selection for missed prayer
    const handleDateChange = async (e) => {
      const selectedDate = e.target.value;
      setDateMissed(selectedDate);
    
      if (location) { // Ensure location is available
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timings = await fetchPrayerTimings(location.latitude, location.longitude, timezone);
        if (timings) {
          setPrayerTimings(timings);
        }
      } else {
        console.error("Location is not available.");
      }
    };


    const handleYes = () => {
      setShowPrompt(false); // Close prompt, no further action
    };

    const handleNo = async () => {
      setShowPrompt(false);
      const userId = user.uid; // Assuming user state holds the current user ID
      const userCurrentDate = new Date().toISOString().split("T")[0]; // Get the user's current date

      try {
        await logMissedPrayer(userId, prayerType, userCurrentDate); // Directly call the existing log function
        console.log("Missed prayer logged successfully");
      } catch (error) {
        console.error("Error logging missed prayer:", error);
      }
    };


  /////////////////////////////////////////////////////////////////////////////
  

  // SIGN IN / SIGN OUT *******************************************************
  // SIGN IN / SIGN OUT *******************************************************
  // SIGN IN / SIGN OUT *******************************************************
  // SIGN IN / SIGN OUT *******************************************************

  // Function to handle sign in with popup
  const handleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const signedInUser = result.user;
      setUser(signedInUser);
      fetchUserDataAndOldestPrayers(signedInUser.uid);
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  // Function to handle sign out
  const handleSignOut = async () => {
    await signOut(auth);
    setUser(null);
    localStorage.removeItem("userLatitude");
    localStorage.removeItem("userLongitude");
    setMissedPrayers({
      fajr: 0,
      zuhr: 0,
      asr: 0,
      maghrib: 0,
      isha: 0,
      roza: 0,
    });
  
  };



  
//ADJUST PRAYER LOGIC ************************************************************
//ADJUST PRAYER LOGIC ************************************************************
//ADJUST PRAYER LOGIC ************************************************************
//ADJUST PRAYER LOGIC ************************************************************

  const adjustPrayer = async (prayer, adjustment) => {
    if (user) {
      const qasrFlag = isQasr && ['zuhr', 'asr', 'isha'].includes(prayer);
  
      if (adjustment > 0) {
        // Use getLocalTodayDate to ensure it’s using today’s date as fallback
        let currentDate = formatDateString(dateMissed || getLocalTodayDate());
        const isDuplicate = await logMissedPrayer(user.uid, prayer, currentDate, qasrFlag);

        if (!isDuplicate) {
          // Only update the prayer count in the local state if no duplicate was found
          const updatedPrayers = { ...missedPrayers };
          updatedPrayers[prayer] = updatedPrayers[prayer] + 1;  // Ensure the increment
          setMissedPrayers(updatedPrayers);
  
          // Update the Firestore 'prayers' field with the new counts
          const userDocRef = doc(db, "users", user.uid);
          try {
            await setDoc(userDocRef, { prayers: updatedPrayers }, { merge: true });
          } catch (error) {
            console.error('Error updating prayers field in Firestore:', error);
          }
        }
      } else if (adjustment < 0) {
        // Decrement logic
        const missedPrayersRef = collection(db, `users/${user.uid}/missedPrayers`);
        const q = query(
          missedPrayersRef,
          where("salahType", "==", prayer),
          where("madeUp", "==", false),
          orderBy("dateMissed", "asc"),
          limit(1)
        );
  
        const querySnapshot = await getDocs(q);
  
        if (querySnapshot.empty) {
          alert(`No missed ${prayer} prayers to decrement.`);
          return;
        }
  
        const docToUpdate = querySnapshot.docs[0];
        await setDoc(docToUpdate.ref, { madeUp: true }, { merge: true });
  
        // Update the prayer count in the local state
        const updatedPrayers = { ...missedPrayers };
        updatedPrayers[prayer] = Math.max(updatedPrayers[prayer] - 1, 0);
  
        setMissedPrayers(updatedPrayers);  // Update state to reflect the new count
  
        // Update the Firestore 'prayers' field with the decremented counts
        const userDocRef = doc(db, "users", user.uid);
        await setDoc(userDocRef, { prayers: updatedPrayers }, { merge: true });
      }
  
      // Refresh the oldest missed prayer date after increment or decrement
      await fetchOldestMissedPrayer(prayer);
      await fetchAllOldestMissedPrayers(user.uid);
    }
  };
  
  
  
  //////////////////////////////////////////////////////////////////////////

  navigator.serviceWorker.addEventListener("message", async (event) => {
    if (event.data?.type === "logMissedPrayer") {
      const { userId, prayerName } = event.data;
      const userCurrentDate = new Date().toISOString().split("T")[0];

      try {
        await logMissedPrayer(userId, prayerName, userCurrentDate); // Call your main logging function
        console.log(`Missed prayer for ${prayerName} logged for user ${userId}`);
      } catch (error) {
        console.error("Error logging missed prayer:", error);
      }
    }
  });



  // RENDER PRAYER COUNTER ************************************************************************
  // RENDER PRAYER COUNTER ************************************************************************
  // RENDER PRAYER COUNTER ************************************************************************
  // RENDER PRAYER COUNTER ************************************************************************


  // Render individual counter for a prayer
  const renderPrayerCounter = (prayerName, prayerKey) => {
    if (loading || missedPrayers[prayerKey] === undefined) {
      return null; // Avoid rendering counters when data is not yet ready
    }
  
    const timing = location && prayerTimings[prayerKey] ? prayerTimings[prayerKey] : null; // Check if timing is available
    const oldestPrayer = oldestMissedPrayers[prayerKey];
    const isOverallOldest = overallOldestPrayer && overallOldestPrayer.salahType === prayerKey && oldestPrayer && overallOldestPrayer.dateMissed === oldestPrayer.dateMissed;
  
    return (
      <div className="prayer-counter-wrapper">
        {/* Counter */}
        <div className="prayer-counter">
          <FaMinusCircle 
            onClick={() => adjustPrayer(prayerKey, -1)} 
            className="counter-icon" 
          />
          <Link to={`/missedPrayers/${prayerKey}`} className="prayer-label">
            <p>{prayerName}: {missedPrayers[prayerKey]}</p>
          </Link>
          <FaPlusCircle 
            onClick={() => adjustPrayer(prayerKey, 1)} 
            className="counter-icon" 
          />
        </div>
  
        {/* Show only oldest missed date if location or timing is not available */}
        {prayerKey !== 'roza' && (oldestPrayer || timing) && (
          <div className="prayer-timing" style={{ textAlign: 'center', fontSize: '12px', color: 'white', marginTop: '5px' }}>
            {oldestPrayer && (
              <span>
                {isOverallOldest ? <FaStar style={{ color: 'white', fontSize: '10px', marginRight: '5px' }} /> : ""}
                {hideDate ? "" : `Oldest missed ${prayerName}: ${formatDateString(oldestPrayer.dateMissed)}`} 
                {oldestPrayer.qasr && " (Q)"}
              </span>
            )}
            {(oldestPrayer && timing && (oldestPrayer.qasr || !hideDate)) && " | "}
            {timing && <span>{timing}</span>}
          </div>
        )}
      </div>
    );
  };
  
  



  //DEFAULT DATA ***************************************************************
  //DEFAULT DATA ***************************************************************
  //DEFAULT DATA ***************************************************************
  //DEFAULT DATA ***************************************************************
  
  // Modified checkIfNewUser to handle the default data setup
  const checkIfNewUser = async (uid) => {
    try {
      const userDocRef = doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);
  
      if (!userDoc.exists()) {
        setShowTutorial(true);  // Show tutorial screen
        setIsNewUser(true); // Mark as a new user
  
        // Retrieve location from local storage if available
        const storedLatitude = localStorage.getItem("userLatitude");
        const storedLongitude = localStorage.getItem("userLongitude");
  
        // Define default data, including location from local storage if it exists
        const defaultData = {
          prayers: {
            fajr: 0,
            zuhr: 0,
            asr: 0,
            maghrib: 0,
            isha: 0,
            roza: 0,
          },
          defaultQasr: false,
          notificationOffset: 5,
          location: storedLatitude && storedLongitude 
            ? { latitude: parseFloat(storedLatitude), longitude: parseFloat(storedLongitude) }
            : { latitude: null, longitude: null }, // Initialize with null if no stored values
        };
  
        // Save default data to Firestore
        await setDoc(userDocRef, defaultData);
        console.log("User document created with default data including location:", defaultData);
  
        setOffsetMinutes(5); // Set the offset in local state as well
  
      } else {
        setIsNewUser(false); // Existing user
      }
    } catch (error) {
      console.error('Error checking if new user:', error);
    }
  };
  //////////////////////////////////////////////////////////////////////////////////////////////

  // USE EFFECT *********************************************************************************
  // USE EFFECT *********************************************************************************
  // USE EFFECT *********************************************************************************
  // USE EFFECT *********************************************************************************

  useEffect(() => {
    // Initial setup useEffect

    const initializeApp = async (authUser) => {
      if (authUser) {
        setUser(authUser);
        setLoading(true);

        // Set initial date and fetch stored offset if available
        setDateMissed(getLocalTodayDate());
        const storedOffset = localStorage.getItem("offsetMinutes");
        if (storedOffset) {
          setOffsetMinutes(parseInt(storedOffset, 10));
        } else {
          // If offset is not stored, set a default of 5 minutes and save it
          const defaultOffset = 5;
          setOffsetMinutes(defaultOffset);
          localStorage.setItem("offsetMinutes", defaultOffset.toString());

          const userDocRef = doc(db, "users", authUser.uid);
          const userDoc = await getDoc(userDocRef);

          await setDoc(userDocRef, { notificationOffset: defaultOffset }, { merge: true });
          if (!userDoc.exists || !userDoc.data().notificationOffset) {
            await setDoc(userDocRef, { notificationOffset: defaultOffset }, { merge: true });
            console.log("Default offset of 5 minutes saved to Firestore.");
          }
        }


        

        // Check if it’s a new user and fetch necessary data
        await checkIfNewUser(authUser.uid);
        await fetchUserPreferences(authUser.uid);

        getLocation(
          async (locationData) => {
            console.log("Location retrieved:", locationData);
            await saveLocationToFirestore(authUser.uid, locationData); // Save to Firestore after location is set
          },
          (error) => {
            console.error("Failed to retrieve location:", error);
          }
        );
        
        await fetchUserDataAndOldestPrayers(authUser.uid);

        // Request notification permissions
        await requestNotificationPermission(authUser);

        await refreshLocation(location)

        setLoading(false);
      } else {
        setUser(null);
        setShowTutorial(false);  // Reset tutorial on logout
        setLoading(false);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, initializeApp);

    return () => unsubscribe();
  }, []);


  // New useEffect to watch for location changes and load prayer timings
  useEffect(() => {
    if (location) {
      //console.log("Location is set. Fetching prayer timings...");
      loadPrayerTimings(location); // Load prayer timings once location is available
    }
  }, [location]); // Dependency array watching only `location`


  // 3. Daily Timing Refresh - Refresh prayer timings daily
  useEffect(() => {
    const refreshTimingsDaily = () => {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (location) {
        fetchPrayerTimings(location.latitude, location.longitude, timezone)
          .then((timings) => {
            setPrayerTimings(timings);
          })
          .catch((error) => console.error("Error fetching daily prayer timings:", error));
      }
    };

    // Refresh every 24 hours
    const interval = setInterval(refreshTimingsDaily, 24 * 60 * 60 * 1000);
    return () => clearInterval(interval); // Clean up interval on unmount
  }, [location]); // Triggers each time `location` changes

  // 4. Notification Timing Update - Refresh notification timings whenever timings or offset changes
  useEffect(() => {
    if (prayerTimings && offsetMinutes !== undefined) {
      updateNotificationTimings();
    }
  }, [prayerTimings, offsetMinutes]); // Runs whenever `prayerTimings` or `offsetMinutes` change

    
    useEffect(() => {
      if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('/firebase-messaging-sw.js')
              .then((registration) => {
                  console.log("Service Worker registered with scope:", registration.scope);

                  // Listen for updates to the service worker
                  registration.onupdatefound = () => {
                      const installingWorker = registration.installing;
                      installingWorker.onstatechange = () => {
                          if (installingWorker.state === 'activated') {
                              // Check if the app is in the foreground
                              const isAppInForeground = document.hasFocus();

                              if (isAppInForeground) {
                                  // App is in the foreground, use `onMessage` in App.js to handle notifications
                                  console.log("App is in foreground: notifications will be handled by onMessage.");
                              } else {
                                  // App is in background or killed, use `onBackgroundMessage` in the service worker
                                  console.log("App is in background/killed: notifications will be handled by onBackgroundMessage.");
                              }
                          }
                      };
                  };
              })
              .catch((error) => {
                  console.error("Service Worker registration failed:", error);
              });
      }
  }, []);

  let lastForegroundMessageId = null;

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      const { prayerName, userId } = payload.data;
      
      if (prayerName && userId) {
        setPrayerType(prayerName);
        setShowPrompt(true);
      } else {
        console.error("Notification data missing 'prayerName' or 'userId'");
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    localStorage.setItem("hideDate", hideDate);
  }, [hideDate]);

  //////////////////////////////////////////////////////////////////////////////////////////////

 


  // LOADING SCREEN ****************************************************************************
  // LOADING SCREEN ****************************************************************************
  // LOADING SCREEN ****************************************************************************
  // LOADING SCREEN ****************************************************************************


  if (loading) {
    return (
      <div className="App">
        {/* Top Bar */}
        <header className="top-bar">
          
          <div className="top-bar-content">
            {/* Empty divs to preserve layout but hide content */}
            <div className="home-section">
              {/* Don't show Home link during loading */}
            </div>
            <div className="greeting">
              {/* Don't show greeting during loading */}
            </div>
            <div className="profile-section">
              {/* Don't show profile section during loading */}
            </div>
          </div>
        </header>
  
        {/* Loading Screen Content */}
          <div className="loading-screen">
          {/* SVG clock face */}
          <div className="clock">
            <img src="/loading.svg" alt="Clock face" className="clock-face" />
            {/* Hour hand */}
            <div className="hour-hand"></div>
            {/* Minute hand */}
            <div className="minute-hand"></div>
          </div>
        </div>
      </div>
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////

  // APP/ROUTER ****************************************************************************
  // APP/ROUTER ****************************************************************************
  // APP/ROUTER ****************************************************************************
  // APP/ROUTER ****************************************************************************

  return (
    <Router>
      <div className="App">
      {showPrompt && (
            <div className="notification-prompt">
              <p>Did you pray {prayerType}?</p>
              <button onClick={handleYes}>Yes</button>
              <button onClick={handleNo}>No I haven't, add one</button>
            </div>
          )}
        {/* Top Bar */}
        <header className="top-bar">
          <div className="top-bar-content">
            {/* Home Icon on the left */}
            <div className="home-section">
              <Link to="/" className="icon">
                <FaHome />
              </Link>
            </div>
  
            {/* Greeting in the center */}
            <div className="greeting">
              <h4>{user ? <>Salam,<br />{user.displayName}</> : 'Sign in Below'}</h4>
            </div>
  
            {/* Profile Section aligned right */}
            <div className="profile-section">
              {user && (
                <>
                  <img src={user.photoURL} alt="User Avatar" className="profile-pic" />
                  <Link to="/settings" className="icon">
                    <FaCog />
                  </Link>
                  <Link to="/tutorial" className="icon"><FaInfoCircle /></Link>
                  <button onClick={handleSignOut} className="icon">
                    <FaPowerOff />
                  </button>
                </>
              )}
            </div>
          </div>
        </header>
  
        {/* Conditionally render Tutorial or main Routes */}
        {showTutorial ? (
          <Tutorial user={user} isNewUser={isNewUser} onClose={() => setShowTutorial(false)} /> // Add onClose to close the tutorial after viewing
        ) : (
          <Routes>
            {/* Main Page */}
            <Route path="/" element={
              user ? (
                <div className="prayer-counters">
                  {/* Date Selector */}
                  <label style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
                    <input 
                      type="checkbox" 
                      checked={isQasr} 
                      onChange={handleQasrToggle}  // Toggle the state on change
                      style={{ display: 'none' }}  // Hide default checkbox
                    />
                    <div 
                      className="custom-toggle"
                      style={{
                        position: 'relative',
                        width: '40px',
                        height: '20px',
                        backgroundColor: isQasr ? '#4caf50' : '#ccc', // Toggle background color
                        borderRadius: '15px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease',  // Smooth background color transition
                      }}
                    >
                      <div
                        style={{
                          position: 'absolute',
                          top: '3px',
                          left: isQasr ? '20px' : '3px',  // Move the circle based on toggle state
                          width: '14px',
                          height: '14px',
                          backgroundColor: 'white',
                          borderRadius: '50%',
                          transition: 'left 0.3s ease',  // Smooth transition for sliding
                        }}
                      />
                    </div>
                    <span style={{ marginLeft: '10px' }}>Qasr</span>
                  </label>
  
                  <label style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
                    Select Date for Missed Prayer: 
                    <input 
                      type="date" 
                      value={dateMissed} 
                      onChange={handleDateChange} 
                      style={{ marginRight: '8px' }} 
                    />
                    <FaCalendarAlt 
                      onClick={(event) => {
                        event.preventDefault(); // Prevents focus on the date input
                        setDateMissed(getLocalTodayDate());
                      }} 
                      className="calendar-icon" 
                      style={{ cursor: 'pointer' }} 
                      title="Reset to today's date"
                    />
                  </label>
                  
  
                  {renderPrayerCounter('Fajr', 'fajr')}
                  <br />
                  {renderPrayerCounter('Zuhr', 'zuhr')}
                  <br />
                  {renderPrayerCounter('Asr', 'asr')}
                  <br />
                  {renderPrayerCounter('Maghrib', 'maghrib')}
                  <br />
                  {renderPrayerCounter('Isha', 'isha')}
                  <br />
                  {renderPrayerCounter('Roza', 'roza')}
                  <br />
                  <label>
                    <input 
                      type="checkbox" 
                      checked={hideDate} 
                      onChange={(e) => setHideDate(e.target.checked)} 
                    />
                    Hide date of oldest missed prayers
                  </label>
                </div>

                
              ) : (
                <>
                  <p>Please sign in with Google below to access the app:</p>
                  <div className="sign-in-container">
                    <FaGoogle 
                      onClick={handleSignIn} 
                      className="google-signin-button" 
                    />
                  </div>
                </>
              )
            } />
  
            {/* Settings Page */}
            <Route
              path="/settings"
              element={
                user ? (
                  <SettingsPage
                    user={user}
                    onUpdatePreferences={refreshPreferences}
                    onLocationRefresh={(lat, lon) => refreshLocation(lat, lon)}
                    missedPrayers={missedPrayers}
                    updatePrayers={setMissedPrayers}
                    updateOffset={setOffsetMinutes}
                    notificationTimings={notificationTimings}
                    offsetMinutes={offsetMinutes}
                  />
                ) : (
                  <Navigate to="/" />  // Redirect to sign-in page if logged out
                )
              }
            />
  
            {/* Missed Prayers Page */}
            <Route 
              path="/missedPrayers/:prayerType" 
              element={
                user ? (
                  <MissedPrayerList 
                    user={user} 
                    missedPrayers={missedPrayers} 
                    oldestMissedPrayers={oldestMissedPrayers}
                    setMissedPrayers={setMissedPrayers} 
                    refreshOldestPrayers={() => fetchAllOldestMissedPrayers(user.uid)} // Pass function to refresh
                  />
                ) : (
                  <Navigate to="/" />  // Redirect to sign-in page if logged out
                )
              } 
            />
  
            {/* Tutorial Route */}
            <Route path="/tutorial" element={<Tutorial user={user} />} /> {/* Pass user prop */}
          </Routes>
        )}
      </div>
    </Router>
  );
  
}


export default App;