// Tutorial.js
import React from 'react';
import { FaCalendarAlt, FaToggleOn, FaStar, FaPlusCircle, FaMinusCircle, FaTable, FaQuran } from 'react-icons/fa';
import { Navigate } from 'react-router-dom';

function Tutorial( { user, isNewUser} ) {
    if (!user) {
        return <Navigate to="/" />; // Redirect to sign-in page if user is null
      }
    
      const handleStart = () => {
        window.location.href = '/'; // Redirect to main app screen
      };

  return (
    <div className="tutorial-screen" style={{ padding: '20px', color: 'white', backgroundColor: '#1a1a1a' }}>
      <h2>Welcome to QazaCounter</h2>
      <p>
        <FaCalendarAlt /> The date will default to today. To change, click the date at the top.
      </p>
      <p>
        <FaToggleOn /> The Qasr toggle logs missed prayers as Qasr, marked with a (Q) on the oldest missed prayer text.
      </p>
      <p>
        <FaStar /> A star indicates the oldest missed prayer. It is prioritized for making up the oldest missed prayer according to Sharia.
      </p>
      <p>
        <FaPlusCircle /> / <FaMinusCircle /> Use these icons to add or subtract missed prayers. By default subtracting a missed prayer will mark the oldest one as made up.
      </p>
      <p>
        <FaTable /> Click on a prayer type to view a table listing missed prayers, ordered by newest. From here, you can delete or modify entries as needed. Entries can only be deleted if they are not made up. To edit this you can click the checkbox here.
      </p>

      {isNewUser && (
        <button 
          onClick={handleStart} 
          style={{ backgroundColor: '#4caf50', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
        >
          Okay, let's go!
        </button>
      )}
    </div>
  );
}

export default Tutorial;