//firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getMessaging, getToken } from "firebase/messaging";  // Import Messaging
import { doc, setDoc } from "firebase/firestore";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDxu1hrjt79NArpL3zIJomKyQFtuDKP_dE",
  authDomain: "qazacounter.firebaseapp.com",
  projectId: "qazacounter",
  storageBucket: "qazacounter.appspot.com",
  messagingSenderId: "135562962712",
  appId: "1:135562962712:web:90ccf658f92268302fa06d",
  measurementId: "G-S7P3V1PQDL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore, Auth, and Messaging
const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const messaging = getMessaging(app);  // Initialize Messaging

// Function to request permission and get the FCM token
export const requestNotificationPermission = async (userId) => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            const token = await getToken(messaging, { vapidKey: "BPuac6rrDEFODgrnUj5hgMUKQUy_EKnhUCNU9Y0E3Q1dk16prJ_l00QY54LxOahDMN6v3U0g1AoC5SAm0y1kbCU" });
            if (token && userId) {
                // Use modular syntax for Firestore
                const userDocRef = doc(db, "users", userId);
                await setDoc(userDocRef, { fcmToken: token }, { merge: true });
                console.log("FCM Token saved:", token);
            }
        } else {
            console.log("Notification permission denied.");
        }
    } catch (error) {
        console.error("Error requesting notification permission:", error);
    }
};


// Export Firestore, Auth, Google Provider, and Messaging
export { app, db, auth, provider, messaging };
